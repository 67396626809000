<template>
  <div class="main-container">
    <el-row justify="space-around">
      <el-col :span="10">
        <span>当前配置模式为：</span>
      </el-col>
      <el-col :span="10">
        <el-select v-model="configType">
          <el-option v-for="item in configTypeData" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-col>
    </el-row>
    <NormalConfig v-if="configType == 1" />
    <EmitterSetting v-if="configType == 2" />
    <!-- <CardPunch v-if="configType == 3" /> -->
  </div>
</template>

<script>
import NormalConfig from "./components/configurationCenter.vue";
import YSDSZConfig from "./components/ysdszConfigGurationCenter.vue";
import CardPunch from "./components/cardPunch.vue";
import EmitterSetting from "./components/emitterSetting.vue"

export default {
  name: "App",
  components: {
    NormalConfig,
    YSDSZConfig,
    CardPunch,
    EmitterSetting,
  },
  data() {
    return {
      configTypeData: [
        {
          label: "机柜/子机",
          value: 1,
        },
        // {
        //   label: "发射器",
        //   value: 2
        // }
        // {
        //   label: "雨伞/登山杖",
        //   value: 2,
        // },
        // {
        //   label: "打卡机",
        //   value: 3,
        // }
      ],
      //配置类型,1:机柜/子机 2:雨伞/登山杖
      configType: 1,
    };
  },
  methods: {},
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;

  .main-container {
    width: 700px;
    height: 900px;
    margin: auto;
    background: #f9fafc;
    border-radius: 6px;
    padding: 32px;
  }
}

.el-row {
  margin-top: 16px;
}

.el-col {
  display: flex;
  align-items: center;
}
</style>
