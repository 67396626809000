<!--
 * @Description 机柜/子机 串口写入
-->
<template>
  <!-- 选择设备类型 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>当前设备类型为：</span>
    </el-col>
    <el-col :span="10">
      <el-select v-model="deviceType" @change="changeDeviceType">
        <el-option v-for="item in selectData" :key="item.deviceType" :label="item.name" :value="item.deviceType" />
      </el-select>
    </el-col>
  </el-row>
  <!-- 主机柜选择网络类型 -->
  <el-row v-if="deviceType == 4" justify="space-around">
    <el-col :span="10">
      <span>选择网络类型为：</span>
    </el-col>
    <el-col :span="10">
      <el-select v-model="MMDNetworkType">
        <el-option v-for="item in MMDNetworks" :key="item.value" :label="item.name" :value="item.value" />
      </el-select>
    </el-col>
  </el-row>
  <!-- 串口是否可用 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>串口是否可用(刷新页面检测)：</span>
    </el-col>
    <el-col :span="10">
      <span>{{ useSerialFlag }}</span>
    </el-col>
  </el-row>
  <!-- 串口连接状态 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>串口连接状态：</span>
    </el-col>
    <el-col :span="10">
      <span>{{ serialStatus }}</span>
      <el-button type="primary" style="margin-left:16px" @click="openPort">打开串口</el-button>
    </el-col>
  </el-row>
  <!-- 分隔线 虚线 -->
  <div class="dotted-line"></div>
  <!-- 输入二维码 -->
  <el-row>
    <el-input style="height: 50px; width: 70%; margin:auto" v-model="scanQR" placeholder="请扫码获取二维码" ref="qrcodeInput" />
    <el-button type="primary" style="margin: auto;" @click="modeChange">{{ isCheck ? "切换至配置" : "切换至功能区" }}</el-button>
  </el-row>
  <!-- 写入、读取 按钮 -->
  <el-row>
    <div style="margin: auto">
      <el-button type="primary" v-if="!isCheck" @click="getSN" style="margin-right: 16px">开始写入</el-button>
      <el-button type="primary" v-if="!isCheck" @click="writePort('read', true, true)">读取SN</el-button>
      <el-button type="primary" v-if="isCheck" @click="checkSN">校验二维码与SN一致性</el-button>
      <el-button type="primary" v-if="isCheck" @click="forcedPush">强制出机</el-button>
      <el-button type="primary" v-if="isCheck" @click="qrCodeUnbind">二维码解绑</el-button>
    </div>
  </el-row>
  <!-- 分隔线 虚线 -->
  <div class="dotted-line"></div>
  <!-- 解析后的数据 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>解析后的数据：</span>
    </el-col>
    <el-col :span="10">
      <span style="white-space: pre-wrap;" :class="readInfo ? 'data-text' : ''">{{ readInfo }}</span>
    </el-col>
    <el-col :span="3">
      <figure class="circle" v-if="ifSuccess"></figure>
    </el-col>
  </el-row>
  <!-- 接收到的串口原始数据 -->
  <el-row justify="space-around">
    <el-col :span="10">
      <span>接收到的串口原始数据：</span>
    </el-col>
    <el-col :span="10">
      <span :class="originalInfo ? 'data-text' : ''">{{ originalInfo }}</span>
    </el-col>
    <el-col :span="3">
    </el-col>
  </el-row>
</template>

<script>
import { encrypt } from "../utils/PortControlUtil";

var port;
export default {
  name: "NormalConfig",
  data() {
    return {
      baseUrl: "https://www.zahuod.com/v1/",
      // baseUrl: "https://dev.zahuod.com/v1/",
      useSerialFlag: "检测中...",
      serialStatus: "串口未连接",
      readInfo: "",
      originalInfo: "",
      deviceType: 0,
      ifSuccess: false,
      selectData: [
        {
          name: "了了导游",
          deviceType: 0,
          value: [
            0x7e, 0x08, 0x0a, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x0d,
          ],
          readValue: [0x7e, 0x02, 0x0c, encrypt(new Array(0x02, 0x0c)), 0x0d],
          writeType: "ZJWrite",
        },
        // {
        //   name: "充电宝",
        //   deviceType: 1,
        //   value: [
        //     0x7e, 0x08, 0x0a, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x0d,
        //   ],

        //   readValue: [0x7e, 0x02, 0x0c, encrypt(new Array(0x02, 0x0c)), 0x0d],
        //   writeType: "ZJWrite",
        // },
        // {
        //   name: "刮大风",
        //   deviceType: 2,
        //   value: [
        //     0x7e, 0x08, 0x0a, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x0d,
        //   ],
        //   readValue: [0x7e, 0x02, 0x0c, encrypt(new Array(0x02, 0x0c)), 0x0d],
        //   writeType: "ZJWrite",
        // },
        // {
        //   name: "自拍杆",
        //   deviceType: 3,
        //   value: [0x7e, 0x0f, 0x0f],
        //   readValue: [0x7e, 0x02, 0x0c, encrypt(new Array(0x02, 0x0c)), 0x0d],
        //   writeType: "ZPGWrite",
        // },
        {
          name: "主机柜",
          deviceType: 1,
          value: [0x7e, 0x69, 0x04],
          readValue: [0x7e, 0x02, 0x05, encrypt(new Array(0x02, 0x05)), 0x0d],
          writeType: "MJGWrite",
        },
        {
          name: "子机柜",
          deviceType: 2,
          value: [0x7e, 0x08, 0x07],
          readValue: [0x7e, 0x02, 0x08, encrypt(new Array(0x02, 0x08)), 0x0d],
          writeType: "SJGWrite",
        },
      ],
      writeValue: "",
      readValue: "",
      scanQR: null,
      isCheck: false,
      //当前待检查的SN
      currentCehckSN: null,
      currentWriteSN: null,
      //当前写SN的类型,不同类型需要传的值不一样,流程也不太一样  "ZJWrite":子机  "MJGWrite":主机柜  "SJGWrite":子机柜
      currentWriteType: null,
      //当前从后台获取的配置资源
      currentConfigRes: null,
      //机柜配置步数,一共两步
      currentJGStep: 1,
      //主机柜网络选择
      MMDNetworks: [
        {
          name: "4G",
          value: "0"
        },
        {
          name: "Wi-Fi",
          value: "1"
        },
      ],
      MMDNetworkType: "0"
    };
  },
  created() {
    this.writeValue = this.selectData[0].value;
    this.readValue = this.selectData[0].readValue;
    this.currentWriteType = this.selectData[0].writeType;
    if ("serial" in navigator) {
      // The Web Serial API is supported.
      this.useSerialFlag = "串口可用";
    } else {
      this.useSerialFlag = "串口不可用(浏览器不支持,请使用chrome或edge浏览器)";
    }
    this.serialStatus = "串口未连接";
    this.scanQR = null;
    this.currentWriteSN = null;
  },
  methods: {
    //选中设备类型
    changeDeviceType(deviceType) {
      //赋值读取SN的命令
      this.readValue = this.selectData[deviceType].readValue;
      this.writeValue = this.selectData[deviceType].value;
      this.currentWriteType = this.selectData[deviceType].writeType;
      this.scanQR = null;
      this.currentWriteSN = null;
      console.log(this.selectData[deviceType]);
    },
    //配置模式和校验模式切换
    modeChange() {
      var that = this;
      that.ifSuccess = false;
      that.readInfo = "";
      that.originalInfo = "";
      that.currentWriteSN = null;
      that.currentCehckSN = null;
      that.scanQR = null;
      this.isCheck = !this.isCheck;
      that.$refs.qrcodeInput.focus();
    },
    //获取SN
    getSN() {
      console.log(this.scanQR);
      var that = this;
      that.ifSuccess = false;
      that.readInfo = "";
      that.originalInfo = "";
      that.currentWriteSN = null;
      that.currentCehckSN = null;
      if (this.scanQR == null || this.scanQR == "") {
        alert("拿二维码内容来换SN,现在空的");
        that.$refs.qrcodeInput.focus();
      } else {
        that.$request
          .post(that.baseUrl + "device/factory/code/bind", {
            qrCode: that.scanQR,
          })
          .then((res) => {
            console.log(
              "接口device/factory/code/bind -- 获取的数据 -->",
              res.data
            );
            if (res.data.code == 200) {
              that.currentConfigRes = res;
              that.handleWrite(res);
            } else {
              alert(res.data.msg);
              that.$refs.qrcodeInput.focus();
              that.scanQR = null;
              that.currentWriteSN = null;
            }
          })
          .catch((error) => {
            alert("获取配置信息失败");
            that.$refs.qrcodeInput.focus();
            console.log(error);
          });
      }
    },
    //校验二维码与SN
    checkSN() {
      console.log(this.scanQR);
      var that = this;
      that.ifSuccess = false;
      that.currentWriteSN = null;
      that.currentCehckSN = null;
      that.readInfo = "";
      that.originalInfo = "";
      if (this.scanQR == null || this.scanQR == "") {
        alert("拿二维码内容来换SN,现在空的");
        that.$refs.qrcodeInput.focus();
      } else {
        that.$request
          .get(that.baseUrl + "device/sn?qrContent=" + that.scanQR)
          .then((res) => {
            console.log(
              "接口device/sn -- 获取的数据 -->",
              res.data
            );
            if (res.data.code == 200) {
              if (res.data.result == null) {
                that.scanQR = null;
                alert("未查询到二维码对应SN,请检查二维码环境");
                that.$refs.qrcodeInput.focus();
              } else {
                that.currentCehckSN = res.data.result;
                that.readInfo = "二维码:" + that.scanQR + "\n";
                that.readInfo = that.readInfo + "二维码对应SN:" + res.data.result + "\n";
                that.writePort('read', false, true)
              }
            } else {
              alert(res.data.msg);
              that.$refs.qrcodeInput.focus();
              that.scanQR = null;
              that.currentCehckSN = null;
            }
          })
          .catch((error) => {
            alert("获取信息失败");
            that.$refs.qrcodeInput.focus();
            console.log(error);
          });
      }
    },
    //强制出机
    forcedPush() {
      var that = this;
      if (this.scanQR == null || this.scanQR == "") {
        alert("二维码为空，请输入");
        that.$refs.qrcodeInput.focus();
      } else {
        that.$request
          .get(that.baseUrl + "device/factory/force-push-all?qrContent=" + that.scanQR)
          .then((res) => {
            console.log(
              "接口device/factory/force-push-all -- 获取的数据 -->",
              res.data
            );
            if (res.data.code == 200) {
              that.scanQR = null;
              alert('强制出机调用成功');
            } else {
              alert(res.data.msg);
              that.$refs.qrcodeInput.focus();
              that.scanQR = null;
            }
          })
          .catch((error) => {
            alert("强制出机异常");
            that.$refs.qrcodeInput.focus();
            console.log(error);
          });
      }
    },
    //二维码解绑
    qrCodeUnbind() {
      var that = this;
      if (this.scanQR == null || this.scanQR == "") {
        alert("二维码为空，请输入");
        that.$refs.qrcodeInput.focus();
      } else {
        that.$request
          .get(that.baseUrl + "device/factory/device-unbinding/abolish?qrContent=" + that.scanQR)
          .then((res) => {
            console.log(
              "接口device/factory/device-unbinding/abolish -- 获取的数据 -->",
              res.data
            );
            if (res.data.code == 200) {
              that.scanQR = null;
              alert('解绑成功');
            } else {
              alert(res.data.msg);
              that.$refs.qrcodeInput.focus();
              that.scanQR = null;
            }
          })
          .catch((error) => {
            alert("解绑异常");
            that.$refs.qrcodeInput.focus();
            console.log(error);
          });
      }
    },
    handleWrite(res) {
      var that = this;
      console.log(
        "'ZJWrite':子机;'MJGWrite':主机柜;'SJGWrite'；当前写SN的类型->",
        that.currentWriteType
      );
      // console.log("writeValue尚未写入的值：", that.writeValue);
      if (that.currentWriteType == "ZJWrite") {
        //了了导游等子机(除自拍杆)获取数据处理,准备写入
        console.log("--- 了了导游等子机(除自拍杆)获取数据处理,准备写入 ---");
        for (var i = 0, j = res.data.result.length; i < j; i++) {
          that.writeValue[3 + i] = res.data.result.charCodeAt(i);
        }
        //当前待写入的SN,后续写入成功上报服务器要用
        that.currentWriteSN = res.data.result;
        //计算CRC
        var tempCRCArray = new Array();
        for (var i = 1; i < that.writeValue.length - 2; i++) {
          tempCRCArray.push(that.writeValue[i]);
        }
        that.writeValue[that.writeValue.length - 2] = encrypt(tempCRCArray);
        console.log("writeValue已经写入的值：", that.writeValue);
        that.writePort(that.currentWriteType, true, false);
        that.readInfo = "服务器读取到的SN:" + res.data.result + "\n";
      } else if (that.currentWriteType == "MJGWrite") {
        that.writeValue = that.selectData[that.deviceType].value;
        console.log("--- 主机柜数据处理,准备写入 ---");
        //机柜配置第一步,SN写入
        that.currentJGStep = 1;
        //机柜获取数据处理,准备写入
        let snLength = res.data.result.sn.length;
        let accountLength = res.data.result.account.length;
        let passwordLength = res.data.result.password.length;
        let authKeyAndNetWork = res.data.result.authKey + that.MMDNetworkType;
        let authKeyLength = authKeyAndNetWork.length;
        //当前待写入的SN,后续写入成功上报服务器要用
        that.currentWriteSN = res.data.result.sn;
        //SN写入
        for (var i = 0; i < snLength; i++) {
          that.writeValue[3 + i] = res.data.result.sn.charCodeAt(i);
        }
        //deviceName写入
        for (var i = 0; i < accountLength; i++) {
          that.writeValue[3 + snLength + i] =
            res.data.result.account.charCodeAt(i);
        }
        //devicePassword写入
        for (var i = 0; i < passwordLength; i++) {
          that.writeValue[3 + snLength + accountLength + i] =
            res.data.result.password.charCodeAt(i);
        }
        //authKey写入
        for (var i = 0; i < authKeyLength; i++) {
          that.writeValue[3 + snLength + accountLength + passwordLength + i] =
            authKeyAndNetWork.charCodeAt(i);
        }
        //0x00 0x01  4G  wifi 
        console.log("authKeyAndNetWork--->", authKeyAndNetWork);
        // let netWorkLength = that.MMDNetworkType.length;
        // for (var i = 0; i < netWorkLength; i++) {
        //   that.writeValue[3 + snLength + accountLength + passwordLength + netWorkLength + i] =
        //     that.MMDNetworkType.charCodeAt(i);
        // }
        //计算CRC
        var tempCRCArray = new Array();
        for (var i = 1; i < that.writeValue.length; i++) {
          tempCRCArray.push(that.writeValue[i]);
        }

        // that.writeValue[that.writeValue.length] = encrypt(tempCRCArray);
        // that.writeValue[that.writeValue.length] = 0x0d;
        if (that.writeValue.indexOf(0x0d) == -1) {
          that.writeValue.push(encrypt(tempCRCArray));
          that.writeValue.push(0x0d);
        }
        console.log("writeValue已经写入的值：", that.writeValue);
        that.writePort(that.currentWriteType, true, false);
        that.readInfo = "服务器读取到的SN:" + res.data.result.sn + "\n";
      } else if (that.currentWriteType == "SJGWrite") {
        console.log("--- 子机柜数据处理,准备写入 ---");
        //子机柜获取数据处理,准备写入
        for (var i = 0, j = res.data.result.length; i < j; i++) {
          that.writeValue[3 + i] = res.data.result.charCodeAt(i);
        }
        //当前待写入的SN,后续写入成功上报服务器要用
        that.currentWriteSN = res.data.result;
        //计算CRC
        var tempCRCArray = new Array();
        for (var i = 1; i < that.writeValue.length; i++) {
          tempCRCArray.push(that.writeValue[i]);
        }
        that.writeValue[that.writeValue.length] = encrypt(tempCRCArray);
        that.writeValue[that.writeValue.length] = 0x0d;
        console.log("writeValue已经写入的值：", that.writeValue);
        that.writePort(that.currentWriteType, true, false);
        that.readInfo = "服务器读取到的SN:" + res.data.result + "\n";
      } else if (that.currentWriteType == "ZPGWrite") {
        console.log("--- 自拍杆数据处理,准备写入 ---");
        //自拍杆获取数据处理,准备写入
        //SN写入
        for (var i = 0, j = res.data.result.sn.length; i < j; i++) {
          that.writeValue[3 + i] = res.data.result.sn.charCodeAt(i);
        }

        for (var i = 0; i < res.data.result.mac.length; i++) {
          that.writeValue[9 + i] = res.data.result.mac.charCodeAt(i);
        }

        //当前待写入的SN,后续写入成功上报服务器要用
        that.currentWriteSN = res.data.result;
        //计算CRC
        var tempCRCArray = new Array();
        for (var i = 1; i < that.writeValue.length; i++) {
          tempCRCArray.push(that.writeValue[i]);
        }
        that.writeValue[that.writeValue.length] = encrypt(tempCRCArray);
        that.writeValue[that.writeValue.length] = 0x0d;
        console.log("writeValue已经写入的值：", that.writeValue);
        that.writePort(that.currentWriteType, true, false);
        that.readInfo = "服务器读取到的SN:" + res.data.result + "\n";
      } else {
        alert("配置异常参数");
        that.$refs.qrcodeInput.focus();
      }
    },
    //机柜MQTT IP等参数写入
    JGIpWrite() {
      console.log("---开始写入机柜MQTT IP等数据---");
      var that = this;
      //机柜配置第二步,mqtt环境配置写入
      that.currentJGStep = 2;
      that.writeValue = [0x7e, 0x08, 0x10];
      //机柜获取数据处理,准备写入
      var ipArray = this.currentConfigRes.data.result.ip.split(".");
      var portStr = parseInt(this.currentConfigRes.data.result.port).toString(
        16
      );
      //ip写入
      for (var i = 0; i < ipArray.length; i++) {
        that.writeValue[3 + i] = parseInt(ipArray[i]);
      }

      //port写入
      for (var i = 0; i < 2; i++) {
        that.writeValue[3 + ipArray.length + i] = parseInt(
          portStr.substring(i * 2, i * 2 + 2),
          16
        );
      }
      //计算CRC
      var tempCRCArray = new Array();
      for (var i = 1; i < that.writeValue.length; i++) {
        tempCRCArray.push(that.writeValue[i]);
      }
      that.writeValue[that.writeValue.length] = encrypt(tempCRCArray);
      //结束符
      that.writeValue[that.writeValue.length] = 0x0d;
      console.log("机柜MQTT IP等数据 ->", that.writeValue);
      //尝试写入
      that.writePort(that.currentWriteType, false, false);
    },
    //上报服务器写入成功
    ackSN() {
      var that = this;
      that.$request
        .post(that.baseUrl + "device/factory/code/bind-ack", {
          sn: that.currentWriteSN,
        })
        .then((res) => {
          console.log(
            "接口device/factory/code/bind-ack --- 响应数据 -->",
            res.data
          );
          if (res.data.code == 200) {
            that.readInfo = that.readInfo + "上报成功------>完全配置成功";
            that.scanQR = "";
            that.currentWriteSN = null;
            that.ifSuccess = true;
            that.$refs.qrcodeInput.focus();
          } else {
            console.log(that.currentWriteSN);
            that.readInfo =
              "SN:" +
              that.currentWriteSN +
              "写入设备成功,告知服务器时失败,请重试";
            alert(res.data.msg);
            that.$refs.qrcodeInput.focus();
          }
        })
        .catch((error) => {
          alert("接口没调成功");
          that.$refs.qrcodeInput.focus();
          console.log(error);
        });
    },
    //开启串口,并打开接收监听
    async openPort() {
      var that = this;
      // 提示用户选择一个串口
      port = await navigator.serial.requestPort();
      try {
        // 等待串口打开
        await port.open({
          baudRate: 9600,
          dataBit: 8,
          stopBit: 1,
          parity: "none",
          flowControl: "none",
        });
      } catch (error) {
        alert("串口打开失败,刷新重试");
      }

      while (port.readable) {
        this.serialStatus = "串口已连接";
        const reader = port.readable.getReader();
        console.log("尝试开始监听");
        //有的数据会被截断，用于拼接数据
        var realValue;
        try {
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              // Allow the serial port to be closed later.
              reader.releaseLock();
              break;
            }

            if (value) {
              // value 是一个 Uint8Array
              console.log("value-->", value);
              //判断数据是否完整，如果只有一位则肯定不完整，暂时记录（根据现有现象做出的特定修改）
              if (value[0].toString() == "126") {
                realValue = value;
              } else {
                var mergedArray = new Uint8Array(realValue.length + value.length);
                mergedArray.set(realValue);
                mergedArray.set(value, realValue.length);
                realValue = mergedArray;
              }
              if (realValue.length == 5 && realValue[1].toString() == "2") {
                //开始解析数据  ACK回复
                for (let i = 0; i < realValue.length; i++) {
                  that.originalInfo += realValue[i].toString() + ",";
                }
                //完全拼好了 再删除最后的逗号
                that.originalInfo = that.originalInfo.slice(0, -1);
                console.log("that.originalInfo-->", that.originalInfo);
                if (that.originalInfo == "126,2,0,2,13") {
                  //ACK回复,7e 02 00 02 0d,配置成功均回复此消息
                  console.log("ACK回复,7e 02 00 02 0d,配置成功均回复此消息");
                  if (that.currentWriteType == "ZJWrite") {
                    //子机配置完成
                    that.readInfo = that.readInfo + "SN写入成功,1s后尝试读取SN进行校验\n";
                    //子机需要反应时间,1s即可
                    setTimeout(() => {
                      that.writePort('read', false, false);
                    }, 1000);
                  } else if (that.currentWriteType == "SJGWrite") {
                    //子机柜配置完成
                    that.readInfo = that.readInfo + "SN写入成功,直接尝试读取SN进行校验\n";
                    //直接进行读取，无需等待
                    that.writePort('read', false, false);
                  } else if (that.currentWriteType == "MJGWrite") {
                    if (that.currentJGStep == 1) {
                      that.readInfo = that.readInfo + "SN写入成功,正在读取SN写入结果,请等待5s\n";
                      //主机需要反应时间,3s以上,5s比较保险
                      setTimeout(() => {
                        that.writePort('read', false, false);
                      }, 5000);
                    } else {
                      that.readInfo =
                        that.readInfo + "SN写入成功,MQTT配置写入成功,正在上报服务器\n";
                      //返回给服务器
                      that.ackSN();
                    }
                  }
                }
                realValue = null;
              } else if (realValue.length >= 11) {
                //开始解析数据 非ACK回复
                for (let i = 0; i < realValue.length; i++) {
                  that.originalInfo += realValue[i].toString() + ",";
                }
                //完全拼好了 再删除最后的逗号
                that.originalInfo = that.originalInfo.slice(0, -1);
                if (
                  realValue[0].toString() +
                  realValue[1].toString() +
                  realValue[2].toString() ==
                  "1261213"
                ) {
                  that.originalInfo = realValue;
                  //7e 0c 0d ,读取到子机SN
                  console.log("1261213 -- 7e 0c 0d ,读取到子机SN");
                  var snArray = [];
                  for (var i = 0; i < 6; i++) {
                    snArray[i] = realValue[3 + i];
                  }
                  console.log(snArray);

                  if (that.currentWriteSN != null) {
                    var readSN = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                    that.readInfo = that.readInfo + "读取到SN:" + readSN + "\n"
                    if (readSN == that.currentWriteSN) {
                      that.readInfo = that.readInfo + "校验通过,回报服务器写入结果\n"
                      that.ackSN();
                    } else {
                      that.readInfo = that.readInfo + "校验不通过,请重新尝试\n"
                    }
                  } else if (that.currentCehckSN != null) {
                    var readSN = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                    that.readInfo = that.readInfo + "读取到当前设备SN:" + readSN + "\n"
                    if (readSN == that.currentCehckSN) {
                      that.readInfo = that.readInfo + "一致性校验通过\n"
                      that.ifSuccess = true;
                    } else {
                      that.readInfo = that.readInfo + "一致性校验不通过\n"
                      that.ifSuccess = false;
                    }
                    that.scanQR = null;
                    that.$refs.qrcodeInput.focus();
                  } else {
                    that.readInfo = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                  }
                } else if (
                  realValue[0].toString() +
                  realValue[1].toString() +
                  realValue[2].toString() ==
                  "1261113"
                ) {
                  that.originalInfo = realValue;
                  //7e 0b 0d ,读取到子机SN
                  console.log("1261113 -- 7e 0b 0d ,读取到子机SN");
                  var snArray = [];
                  for (var i = 0; i < 6; i++) {
                    snArray[i] = realValue[3 + i];
                  }
                  console.log(snArray);

                  if (that.currentWriteSN != null) {
                    var readSN = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                    that.readInfo = that.readInfo + "读取到SN:" + readSN + "\n"
                    if (readSN == that.currentWriteSN) {
                      that.readInfo = that.readInfo + "校验通过,回报服务器写入结果\n"
                      that.ackSN();
                    } else {
                      that.readInfo = that.readInfo + "校验不通过,请重新尝试\n"
                    }
                  } else if (that.currentCehckSN != null) {
                    var readSN = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                    that.readInfo = that.readInfo + "读取到当前设备SN:" + readSN + "\n"
                    if (readSN == that.currentCehckSN) {
                      that.readInfo = that.readInfo + "一致性校验通过\n"
                      that.ifSuccess = true;
                    } else {
                      that.readInfo = that.readInfo + "一致性校验不通过\n"
                      that.ifSuccess = false;
                    }
                    that.scanQR = null;
                    that.$refs.qrcodeInput.focus();
                  } else {
                    that.readInfo = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                  }
                } else if (
                  realValue[0].toString() +
                  realValue[1].toString() +
                  realValue[2].toString() ==
                  "12686"
                ) {
                  that.originalInfo = realValue;
                  //7e 08 06 ,读取到主机柜SN
                  console.log("12686 -- 7e 08 06 ,读取到主机柜SN");
                  var snArray = [];
                  for (var i = 0; i < 6; i++) {
                    snArray[i] = realValue[3 + i];
                  }
                  console.log(snArray);

                  if (that.currentWriteSN != null) {
                    var readSN = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                    that.readInfo = that.readInfo + "读取到SN:" + readSN + "\n"
                    if (readSN == that.currentWriteSN) {
                      that.readInfo = that.readInfo + "SN写入校验通过,尝试写入MQTT配置,请等候5s\n"
                      //写入MQTT配置
                      setTimeout(() => {
                        that.JGIpWrite();
                      }, 5000);
                    } else {
                      that.readInfo = that.readInfo + "校验不通过,请重新尝试\n"
                    }
                  } else if (that.currentCehckSN != null) {
                    var readSN = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                    that.readInfo = that.readInfo + "读取到当前设备SN:" + readSN + "\n"
                    if (readSN == that.currentCehckSN) {
                      that.readInfo = that.readInfo + "一致性校验通过\n"
                      that.ifSuccess = true;
                    } else {
                      that.readInfo = that.readInfo + "一致性校验不通过\n"
                      that.ifSuccess = false;
                    }
                    that.scanQR = null;
                    that.$refs.qrcodeInput.focus();
                  } else {
                    that.readInfo = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                  }
                } else if (
                  realValue[0].toString() +
                  realValue[1].toString() +
                  realValue[2].toString() ==
                  "12689"
                ) {
                  that.originalInfo = realValue;
                  //7e 08 09 ,读取到子机柜SN
                  console.log("12689 -- 7e 08 09 ,读取到子机柜SN");
                  var snArray = [];
                  for (var i = 0; i < 6; i++) {
                    snArray[i] = realValue[3 + i];
                  }
                  console.log(snArray);

                  if (that.currentWriteSN != null) {
                    var readSN = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                    that.readInfo = that.readInfo + "读取到SN:" + readSN + "\n"
                    if (readSN == that.currentWriteSN) {
                      that.readInfo = that.readInfo + "校验通过,回报服务器写入结果\n"
                      that.ackSN();
                    } else {
                      that.readInfo = that.readInfo + "校验不通过,请重新尝试\n"
                    }
                  } else if (that.currentCehckSN != null) {
                    var readSN = that.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                    that.readInfo = that.readInfo + "读取到当前设备SN:" + readSN + "\n"
                    if (readSN == that.currentCehckSN) {
                      that.readInfo = that.readInfo + "一致性校验通过\n"
                      that.ifSuccess = true;
                    } else {
                      that.readInfo = that.readInfo + "一致性校验不通过\n"
                      that.ifSuccess = false;
                    }
                    that.scanQR = null;
                    that.$refs.qrcodeInput.focus();
                  } else {
                    this.readInfo = this.uint8ArrayToString(
                      new Uint8Array(snArray)
                    );
                  }
                } else {
                  that.readInfo = "正在接收ack回复消息";
                }
                realValue = null;
              }
            }
          }
        } catch (error) {
          // TODO: Handle non-fatal read error.
          console.log("发生错误,error是:" + error);
          console.log(port)
          if (port.readable == null) {
            alert("串口读取异常,请重新打开串口")
            this.serialStatus = "串口已断开";
          }
        } finally {
          reader.releaseLock();
        }
      }
    },
    //向串口写入数据
    async writePort(type, clear, reset) {
      this.originalInfo = "";
      if (clear == true) {
        this.readInfo = "";
      }
      if (reset == true) {
        this.currentWriteSN = null;
        this.ifSuccess = false;
      }

      var that = this;
      if (port == null || port.writable == null || port.readable == null) {
        //必须保证串口读写均正常
        alert("串口没开或断开");
      } else {
        const writer = port.writable.getWriter();
        switch (type) {
          //读取子机SN
          case "read": {
            //直接写入十六进制数据,CRC直接填入计算,记录命令作用
            await writer.write(new Uint8Array(that.readValue));
            console.log(that.readValue);
            console.log("读取指令发送成功");
            break;
          }
          //子机SN写入
          case "ZJWrite": {
            await writer.write(new Uint8Array(that.writeValue));
            console.log("子机SN写入指令发送成功");
            break;
          }
          //自拍杆写入
          case "ZPGWrite": {
            await writer.write(new Uint8Array(that.writeValue));
            console.log("自拍杆SN写入指令发送成功");
            break;
          }
          case "MJGWrite": {
            await writer.write(new Uint8Array(that.writeValue));
            console.log("主机柜SN写入指令发送成功");
            break;
          }
          case "SJGWrite": {
            await writer.write(new Uint8Array(that.writeValue));
            console.log("子机柜SN写入指令发送成功");
            break;
          }
          default: {
            break;
          }
        }
        // 允许稍后关闭串口。
        writer.releaseLock();
      }
    },
    //uint8array转string
    uint8ArrayToString(fileData) {
      var dataString = "";
      for (var i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i]);
      }

      return dataString;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dotted-line {
  width: 90%;
  border: 1px dashed #999;
  margin: 32px auto;
}

.data-text {
  width: 100%;
  word-wrap: break-word;
  background: #E5E9F2;
  border-radius: 4px;
  padding: 6px;
}

.circle {
  display: block;
  border-radius: 50%;
  margin-left: 15px;
  height: 30px;
  width: 30px;
  margin: 0;
  background: greenyellow;
}
</style>
