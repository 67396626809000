<!-- 发射器出厂配置 -->
<template>
    <!-- 串口是否可用 -->
    <el-row justify="space-around">
        <el-col :span="10">
            <span>串口是否可用(刷新页面检测)：</span>
        </el-col>
        <el-col :span="10">
            <span>{{ useSerialFlag }}</span>
        </el-col>
    </el-row>
    <!-- 串口连接状态 -->
    <el-row justify="space-around">
        <el-col :span="10">
            <span>串口连接状态：</span>
        </el-col>
        <el-col :span="10">
            <span>{{ serialStatus }}</span>
            <el-button type="primary" style="margin-left:16px" @click="openPort">打开串口</el-button>
        </el-col>
    </el-row>
    <!-- 分隔线 虚线 -->
    <div class="dotted-line"></div>
    <!-- 输入二维码 -->
    <el-row>
        <el-input style="height: 50px; width: 70%; margin:auto" v-model="scanQR" placeholder="请扫码获取二维码"
            ref="qrcodeInput" />
        <el-button type="primary" style="margin: auto;" @click="modeChange">{{ isCheck ? "切换至配置" : "切换至校验"
            }}</el-button>
    </el-row>
    <!-- 写入、读取 按钮 -->
    <el-row>
        <div style="margin: auto">
            <el-button type="primary" v-if="!isCheck" @click="getWriteInfo()"
                style="margin-right: 16px">开始写入</el-button>
            <el-button type="primary" v-if="!isCheck" @click="readSN">读取SN</el-button>
            <el-button type="primary" v-if="isCheck" @click="checkSN">校验二维码与SN一致性</el-button>
        </div>
    </el-row>
    <!-- 分隔线 虚线 -->
    <div class="dotted-line"></div>
    <!-- 解析后的数据 -->
    <el-row justify="space-around">
        <el-col :span="10">
            <span>解析后的数据：</span>
        </el-col>
        <el-col :span="10">
            <span :class="readInfo ? 'data-text' : ''">{{ readInfo }}</span>
        </el-col>
    </el-row>
    <!-- 接收到的串口原始数据 -->
    <el-row justify="space-around">
        <el-col :span="10">
            <span>接收到的串口原始数据：</span>
        </el-col>
        <el-col :span="10">
            <span style="white-space: pre-wrap;" :class="originalInfo ? 'data-text' : ''">{{ originalInfo }}</span>
        </el-col>
    </el-row>
</template>

<script>
import { encrypt } from "../utils/PortControlUtil";
var port;
export default {
    name: "EmitterSetting",
    data() {
        return {
            // baseUrl: "https://www.zahuod.com/v1/",
            // baseUrl: "https://dev.zahuod.com/v1/",
            baseUrl: "http://api.zahuodian.k8s/",
            useSerialFlag: "检测中...",
            serialStatus: "串口未连接",
            readInfo: "",
            originalInfo: "",
            isCheck: false,
            deviceType: 0,
            writeValue: "",
            readValue: [0x7e, 0x04, 0x05, 0x00, 0x00, 0x07, 0x0d],
            scanQR: null,
            currentWriteSN: null,
            //当前从后台获取的配置资源
            currentConfigRes: null,
            //配置步数,一共两步
            currentStep: 1,
            currentIsRead: true,
            // writeData: {
            //     ip: "121.196.56.34",
            //     port: "18884",
            //     blueToothName: "ZHD-F20001xxxxxx",
            //     sn: "F20001",
            //     account: "oylo2vozfu3tj8wqe5fahmy9udasp96z",
            //     password: "jc9vgj9y2y3lhvjo2dds6721hlpbmpzd",
            //     authKey: "92s2bnuj4v7ito3lszpn1w84c73a9as7"
            // },
            //临时存储数据，有时候接受数据不完整需要拼接，使用此字段
            tempReceiveInfo: null
        };
    },
    created() {
        if ("serial" in navigator) {
            // The Web Serial API is supported.
            this.useSerialFlag = "串口可用待打开";
        } else {
            this.useSerialFlag = "串口不可用(浏览器不支持,请使用chrome或edge浏览器)";
        }
        this.serialStatus = "串口未连接";
        this.scanQR = null;
        this.currentWriteSN = null;
    },
    methods: {
        handleWrite(writeData) {
            var that = this;
            var writeValue = [0x7e, 0x7e, 0x04];
            //sn
            that.currentWriteSN = writeData.deviceSn;
            for (var i = 0, j = writeData.deviceSn.length; i < j; i++) {
                writeValue.push(writeData.deviceSn.charCodeAt(i));
            }
            //blueToothName
            writeData.blueToothIBeacon.bluetoothName = "ZHD-" + that.scanQR;
            for (var i = 0, j = writeData.blueToothIBeacon.bluetoothName.length; i < j; i++) {
                writeValue.push(writeData.blueToothIBeacon.bluetoothName.charCodeAt(i));
            }
            //ServerIP
            var netIp = writeData.net.mqttHost.split('.');
            netIp.forEach((item, index) => {
                writeValue.push(parseInt(item));
            })
            //port
            var portHex = parseInt(writeData.net.mqttPort).toString(16);
            writeValue.push(parseInt(portHex.substring(0, 2), 16));
            writeValue.push(parseInt(portHex.substring(2, 4), 16));
            //account
            for (var i = 0, j = writeData.net.mqttAccount.length; i < j; i++) {
                writeValue.push(writeData.net.mqttAccount.charCodeAt(i));
            }
            //password
            for (var i = 0, j = writeData.net.mqttPwd.length; i < j; i++) {
                writeValue.push(writeData.net.mqttPwd.charCodeAt(i));
            }
            //authKey
            for (var i = 0, j = writeData.net.mqttAuthKey.length; i < j; i++) {
                writeValue.push(writeData.net.mqttAuthKey.charCodeAt(i));
            }
            //计算CRC
            var tempCRCArray = new Array();
            for (var i = 1; i < writeValue.length; i++) {
                tempCRCArray.push(writeValue[i]);
            }
            // writeValue.push(parseInt(encrypt(tempCRCArray), 16))
            writeValue.push(parseInt("0d", 16))
            this.writePort(writeValue, false)
        },
        //配置模式和校验模式切换
        modeChange() {
            var that = this;
            that.ifSuccess = false;
            that.readInfo = "";
            that.originalInfo = "";
            that.currentWriteSN = null;
            that.currentCehckSN = null;
            that.scanQR = null;
            this.isCheck = !this.isCheck;
            that.$refs.qrcodeInput.focus();
        },
        //获取info写入数据
        getWriteInfo() {
            console.log(this.scanQR);
            var that = this;
            that.ifSuccess = false;
            that.readInfo = "";
            that.originalInfo = "";
            that.currentWriteSN = null;
            that.currentCehckSN = null;
            if (this.scanQR == null || this.scanQR == "") {
                alert("拿二维码内容来换SN,现在空的");
                that.$refs.qrcodeInput.focus();
            } else {
                that.$request
                    .post(that.baseUrl + "zahuodian-bizline-zahuod/api/v1/admin/pages/device-production/qr-bind", {
                        qrContent: that.scanQR,
                    }, {
                        headers: {
                            "zahuo-token": "9c188c9e-bab2-421d-8aaa-416a34c1f22e"
                        }
                    })
                    .then((res) => {
                        console.log(
                            "接口 -- 获取的数据qr-bind -->",
                            res.data
                        );
                        if (res.data.code == "00000") {
                            that.currentConfigRes = res.data.data;
                            that.handleWrite(res.data.data);
                        } else {
                            alert(res.data.msg);
                            that.$refs.qrcodeInput.focus();
                            that.scanQR = null;
                            that.currentWriteSN = null;
                        }
                    })
                    .catch((error) => {
                        alert("获取配置信息失败");
                        that.$refs.qrcodeInput.focus();
                        console.log(error);
                    });
            }
        },
        //上报服务器写入成功
        ackSN(mac) {
            console.log("mac:" + mac)
            console.log(this.currentConfigRes)
            var that = this;
            that.currentConfigRes.blueToothIBeacon.bluetoothMacAddress = mac;
            that.$request.post(that.baseUrl + "zahuodian-bizline-zahuod/api/v1/admin/pages/device-production/qr-bind-ack", {
                qrContent: that.scanQR,
                blueToothIBeacon: that.currentConfigRes.blueToothIBeacon,
                deviceSn: that.currentConfigRes.deviceSn
            }, {
                headers: {
                    "zahuo-token": "9c188c9e-bab2-421d-8aaa-416a34c1f22e"
                }
            })
                .then((res) => {
                    console.log(res.data);
                    if (res.data.code == "00000") {
                        that.readInfo = that.readInfo + "------>完全配置成功";
                        that.scanQR = "";
                        that.currentConfigRes = null;
                    } else {
                        console.log(that.currentWriteSN);
                        that.readInfo =
                            "SN:" +
                            that.currentWriteSN +
                            "写入设备成功,告知服务器时失败,请重试";
                        alert(res.data.msg);
                    }
                })
                .catch((error) => {
                    alert("接口没调成功");
                    console.log(error);
                });
        },
        //读取sn
        async readSN() {
            this.originalInfo = "";
            this.readInfo = "";
            this.currentWriteSN = null;
            this.ifSuccess = false;
            var that = this;
            if (port == null || port.writable == null || port.readable == null) {
                //必须保证串口读写均正常
                alert("串口没开或断开");
            } else {
                const writer = port.writable.getWriter();
                await writer.write(new Uint8Array(that.readValue));
                console.log(that.readValue);
                console.log("读取指令发送成功");
                // 允许稍后关闭串口。
                writer.releaseLock();
            }
        },
        async checkSN() {
            if (this.scanQR == null) {
                alert("扫码后才可进行校验");
                this.$refs.qrcodeInput.focus();
            } else {
                this.originalInfo = "";
                this.readInfo = "";
                this.currentWriteSN = null;
                this.ifSuccess = false;
                var that = this;
                if (port == null || port.writable == null || port.readable == null) {
                    //必须保证串口读写均正常
                    alert("串口没开或断开");
                } else {
                    const writer = port.writable.getWriter();
                    await writer.write(new Uint8Array(that.readValue));
                    console.log(that.readValue);
                    console.log("读取指令发送成功");
                    // 允许稍后关闭串口。
                    writer.releaseLock();
                }
            }
        },
        //由后台进行SN检查
        netCheckSN(readSN) {
            var that = this;
            //狗屎一个接口传不同字段代表不同功能，传二维码和sn代表查询二者是否有关联
            that.$request
                .post(that.baseUrl + "zahuodian-bizline-zahuod/api/v1/admin/pages/device-production/qr-sn-validate", {
                    qrContent: that.scanQR,
                    deviceSn: readSN
                }, {
                    headers: {
                        "zahuo-token": "9c188c9e-bab2-421d-8aaa-416a34c1f22e"
                    }
                })
                .then((res) => {
                    console.log(
                        "接口qr-sn-validate -- 获取的数据 -->",
                        res.data
                    );
                    if (res.data.code == "00000") {
                        if (res.data.data.validate) {
                            this.readInfo += ",验证通过"
                        } else {
                            this.readInfo += ",验证不通过"
                        }
                    } else {
                        alert(res.data.msg);
                        that.$refs.qrcodeInput.focus();
                        that.scanQR = null;
                        that.currentWriteSN = null;
                    }
                })
                .catch((error) => {
                    alert("校验失败,与后台通讯异常");
                    that.$refs.qrcodeInput.focus();
                    console.log(error);
                });
        },
        //开启串口,并打开接收监听
        async openPort() {
            var that = this;
            // 提示用户选择一个串口
            port = await navigator.serial.requestPort();
            try {
                // 等待串口打开
                await port.open({
                    baudRate: 115200,
                    dataBit: 8,
                    stopBit: 1,
                    parity: "none",
                    flowControl: "none",
                });
            } catch (error) {
                alert("串口打开失败,刷新重试");
            }

            while (port.readable) {
                this.serialStatus = "串口已连接";
                const reader = port.readable.getReader();
                console.log("尝试开始监听");
                //有的数据会被截断，用于接收SN拼接数据
                var snInfoValue = null;
                //有的数据会被截断,用于接收配置回调信息
                var settingBackInfoValue = null;

                try {
                    while (true) {
                        const { value, done } = await reader.read();
                        if (done) {
                            // Allow the serial port to be closed later.
                            reader.releaseLock();
                            break;
                        }
                        if (value) {
                            // value 是一个 Uint8Array
                            //原始数据
                            var valueStr = value.toString();
                            // console.log(valueStr)
                            if (valueStr.indexOf("66,84,95,77,65,67") != -1) {
                                //由于太多乱七八糟的数据,过滤回调，BT_MAC为数据写入成功的开头
                                settingBackInfoValue = valueStr.substring(valueStr.indexOf("66,84,95,77,65,67"));
                                // console.log("找头:"+settingBackInfoValue)
                            } else if (valueStr.indexOf("126,8,5") != -1) {
                                snInfoValue = valueStr.substring(valueStr.indexOf("126,8,5"));
                            } else if (snInfoValue != null) {
                                //拼接sn信息
                                snInfoValue += "," + valueStr;
                            } else if (settingBackInfoValue != null) {
                                //拼接配置回调信息
                                settingBackInfoValue += "," + valueStr;
                            }
                            //SN数据处理
                            if (snInfoValue != null && snInfoValue.length >= 29) {
                                var infoArray = snInfoValue.substring(0, 29).split(",")
                                var snArray = [];
                                for (var i = 0; i < 6; i++) {
                                    snArray[i] = infoArray[3 + i];
                                }
                                var readSN = that.uint8ArrayToString(new Uint8Array(snArray));
                                console.log(new Uint8Array(snArray));
                                if (that.isCheck) {
                                    //进行SN检查
                                    that.readInfo = that.readInfo + "读取到当前设备SN:" + readSN + "\n";
                                    //后台进行SN检查
                                    that.netCheckSN(readSN);
                                } else {
                                    //正常读取到SN，直接显示即可
                                    that.originalInfo = valueStr;
                                    that.readInfo = "读取到的SN:" + readSN;
                                }
                                snInfoValue = null;
                            }
                            //配置回调处理
                            if (settingBackInfoValue != null && settingBackInfoValue.length >= 77) {
                                //数据够了,可以开始处理数据了
                                that.originalInfo = settingBackInfoValue;
                                //移除 空，回车，回车，换行, 和 回车，回车，换行  顺序不能乱
                                var valueArray = settingBackInfoValue.substring(0, 88).replaceAll(",0,13,13,10", "").replaceAll("13,13,10,", "").split(",");
                                console.log(valueArray);
                                that.readInfo = String.fromCharCode(...valueArray);
                                console.log(that.readInfo)
                                that.ackSN(that.readInfo.replace("BT_MAC", ""));
                                //重置拼接数据
                                settingBackInfoValue = null;
                            }
                        }
                    }
                } catch (error) {
                    // TODO: Handle non-fatal read error.
                    console.log("发生错误,error是:" + error);
                } finally {
                    reader.releaseLock();
                }
            }
        },
        //向串口写入数据
        async writePort(writeValue, currentIsRead) {
            this.currentIsRead = currentIsRead;
            var that = this;
            if (port == null) {
                alert("串口没开");
            } else {
                const writer = port.writable.getWriter();
                //直接写入标签
                await writer.write(new Uint8Array(writeValue));
                console.log(writeValue);
                console.log("写入成功");
                // 允许稍后关闭串口。
                writer.releaseLock();
            }
        },
        //uint8array转string
        uint8ArrayToString(fileData) {
            var dataString = "";
            for (var i = 0; i < fileData.length; i++) {
                dataString += String.fromCharCode(fileData[i]);
            }

            return dataString;
        },
    },
};
</script>

<style scoped>
.dotted-line {
    width: 90%;
    border: 1px dashed #999;
    margin: 32px auto;
}

.data-text {
    width: 100%;
    word-wrap: break-word;
    background: #E5E9F2;
    border-radius: 4px;
    padding: 6px;
}

.circle {
    display: block;
    border-radius: 50%;
    margin-left: 15px;
    height: 30px;
    width: 30px;
    margin: 0;
    background: greenyellow;
}
</style>